<template>

  <!-- Draft Form -->
  <div class="draft-form">

    <!-- Entry Form -->
    <j-card-text class="py-0">
      <j-form
        v-if="draft.hasSource"
        model="drafts.draft"
      >

        <!-- Release Date -->
        <j-control name="released_on" />

        <!-- Format -->
        <j-control
          v-if="showFormatSelector"
          name="format"
          :label="draft.sourceType == 'assignment' ? 'Section' : 'Format'"
        />

      </j-form>

      <!-- Item Form -->
      <component
        :is="draft.format"
        :draft="draft"
      />

    </j-card-text>
  </div>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters } = createNamespacedHelpers('drafts')

export default {
  name: 'DraftForm',
  // -------------
  // Components ==
  // -------------
  components: {
    "event-reviews": () => import("./forms/DraftEventReview"),
    "features": () => import("./forms/DraftFeature"),
    "gear-reviews": () => import("./forms/DraftGearReview"),
    "info": () => import("./forms/DraftInfo"),
    "interviews": () => import("./forms/DraftInterview"),
    "news": () => import("./forms/DraftNews"),
    "music-reviews": () => import("./forms/DraftMusicReview"),
    "print-reviews": () => import("./forms/DraftPrintReview"),
    "screen-reviews": () => import("./forms/DraftScreenReview"),
    "staff-picks": () => import("./forms/DraftStaffPick")
},
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'draft'
    ]),
    // Getters
    ...mapGetters([
      'draftSection'
    ]),
    // UI
    showFormatSelector () {
      return this.draft.sourceType == 'assignment'
        || this.draftSection.volume_slug == 'columns'
    }
  }
}
</script>
