<template>

  <!-- Draft Form Panel -->
  <div id="draft-form-panel">
    <j-card title="Article">

      <!-- Assignment Source -->
      <OppTile
        v-if="draft.source.tag19"
        :opp="draft.source"
        class="opp-source ma-4"
      >
        <template #footer>
          <v-spacer />

          <!-- Link to item -->
          <j-button
            label="View Assignment"
            @action="handleViewOpp"
            class="my-2 float-right"
          />
  
        </template>
      </OppTile>

      <!-- Section Source -->
      <SectionTile
        v-else
        :section="draftSection"
        class="section-source ma-4"
      />

      <!-- Draft Form -->
      <DraftForm />

      <!-- Actions -->
      <template #actions>

        <!-- Create -->
        <j-button
          v-if="draft.hasSource"
          label="Create Draft"
          :processing="draft.article.saving"
          :disabled="draft.pristine"
          @action="handleCreate"
        />

      </template>

      <!-- Options -->
      <template #options>

        <!-- Reset -->
        <j-button
          v-if="!draft.article.saving && draft.modified"
          label="Reset"
          type="option"
          @action="handleReset"
        />

      </template>
    </j-card>
  </div>
</template>

<script>

// Components
import DraftForm from '@/components/Drafts/DraftForm'
import OppTile from '@/components/widgets/Opps/OppTile'
import SectionTile from '@/components/widgets/sections/SectionTile'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('drafts')

export default {
  name: 'DraftFormView',
  // -------------
  // Components ==
  // -------------
  components: {
    DraftForm,
    OppTile,
    SectionTile
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(to, _from, next) {
    next(vm => {
      vm.newDraft(vm.sourceParams)
    })
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: 'Add Article'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'draft'
    ]),
    // Getters
    ...mapGetters([
      'draftSection',
      'loading'
    ]),
    // Helpers
    sourceParams () {
      return {
        type: this.type,
        id: this.id
      }
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'newDraft',
      'saveDraft'
    ]),
    // Handlers
    handleViewOpp () {
      window.open(`/opportunities/${this.draft.source.tag19}`, '_blank')
    },
    handleReset () {
      this.$router.push({ name: 'draft-create' })
    },
    handleCreate () {
      this.saveDraft().then(() => {
        const tag19 = this.draft.article.get('tag19')
        this.$router.push({ name: 'articles-view', params: { tag19 }})
      })
    }
  }
}
</script>
